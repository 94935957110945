import api from "@/service/api";
export default {

  state: {
    request:
    {
      sf_no: '',
      gsm: '',
      code:''
    },
    sf:null,
    customer:null,
    devices:[],
    current_device :null,
    customer_informations:[],
    invoice_item:
    {
      city_item:null,
      district_item:null
    },
    payments:[],
  },
  getters: {},
  actions: 
  { 
    requestServiceForm({ state }) {
      let postData = { sf_no: state.request.sf_no, gsm: state.request.gsm }
      return new Promise((resolve, reject) => 
      {
        api.action("requestServiceForm", { ...postData }).then(q => {
          resolve(q);
        })
      })
    },
    verifyOTPCodeServiceForm({ state }) {
      let postData = { sf_no: state.request.sf_no, gsm: state.request.gsm , code:state.request.code }
      return new Promise((resolve, reject) => 
      {
        api.action("verifyOTPCodeServiceForm", { ...postData }).then(q => {
          resolve(q);
        })
      })
    },
    downloadServiceForm({state})
    {
      let postData = { sf_uniq:state.sf.sf.sf_uniq,customer_uniq:state.sf.customer.customer_uniq }
      return new Promise((resolve, reject) => 
      {
        api.action("downloadServiceForm", { ...postData }).then(q => {
          resolve(q);
        })
      })
    }

  },
}
