const { default: axios } = require("axios");

class ApiService {
    constructor() { }
    static api_config =
        {
            //url: 'https://localhost/tka_app/api/api',
            url: 'https://api.tkabilisim.com.tr/api',
            controller: ''
        }

    static async action(action, data, controller = '') {

        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';


        let url = this.api_config.url + '/' + controller;

        return new Promise(resolve => {
            axios.post(url, {
                action,
                ...data
            }).then(q => {
                let response = q.data;
                resolve(response);
            })
        })
    }

    static async actionget(action, data) {

        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';

        return new Promise(resolve => {
            axios.get(this.api_config.url, {
                action,
                ...data
            }).then(q => {
                let response = q.data;
                resolve(response);
            })
        })
    }

    static getIG() {
        return new Promise(resolse => {
            axios.get('https://tkabilisim.com.tr/ajax/getig').then(q => {
                let response = q.data;
                resolse(response);
            })
        })
    }

    static downloadserviceform(sf_uniq, customer_uniq, downloadfilename) {
        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';


        return new Promise(resolve => {
            let data = { sf_uniq: sf_uniq, customer_uniq: customer_uniq }
            axios.post(this.api_config.url, {
                action: 'downloadServiceForm',
                ...data
            }).then(q => {

                let response = q.data;

                const linkSource = `data:application/pdf;base64,${response.data}`;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = downloadfilename;
                downloadLink.click();

                resolve(q);
            })
        })



    }
};


export default ApiService;
